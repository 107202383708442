import { useContext, useState } from 'react';
import { Box, Button, Card, Checkbox, Icon, Input, Link, Text } from '@bitrise/bitkit';

import PublicPage from '@/components/page/PublicPage';
import PageContent from '@/components/page/PageContent/PageContent';
import PageTitle, { PageTitleHeading, PageTitleSubheading } from '@/components/page/PageTitle/PageTitle';
import NotificationSlot from '@/components/NotificationSlot/NotificationSlot';
import BackButton from '@/components/buttons/BackButton/BackButton';
import LoginButtonGroup from '@/components/buttons/LoginButtonGroup/LoginButtonGroup';
import LoginCard from '@/components/LoginCard/LoginCard';
import StaticHeader from '@/components/page/PageHeader/StaticHeader';
import { GlobalPropsContext } from '@/components/page/GlobalPropsContext';
import useMonolithApiMutation from '@/hooks/api/useMonolithApiMutation';
import SignInForm from './components/SignInForm';
import useSignInFlashMessage from './hooks/useSignInFlashMessage';

const SSOEmailForm = ({ onBack }: { onBack: () => void }) => {
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const { mutate, reset, isPending } = useMonolithApiMutation('/users/sign-in/saml', 'post', {
    onSettled() {
      reset();
      setSent(true);
    },
  });
  return sent ? (
    <Box marginX="auto" maxWidth="35rem" display="flex" flexDirection="column" gap="40">
      <div>
        <Box display="flex" flexDirection="row" alignItems="center" gap="12" marginBottom="16">
          <Icon size="32" name="Mail" />
          <Text textStyle="heading/h1">Doing great</Text>
        </Box>
        <Text>
          We've sent a magic link to your email address to sign in. Please check your inbox (and your spam folder just
          in case).
        </Text>
      </div>
      <div>
        <Text textStyle="heading/h1" marginBottom="16">
          Didn't get the email?
        </Text>
        <Text>
          If you didn’t receive the email, you can request another magic link or reach out to our{' '}
          <Link colorScheme="purple" isExternal href="https://bitrise.io/contact">
            support team
          </Link>{' '}
          for assistance.
        </Text>
      </div>
      <Button isLoading={isPending} size="md" variant="secondary" onClick={() => mutate({ email })} alignSelf="start">
        Re-send email
      </Button>
    </Box>
  ) : (
    <Card marginX="auto" maxWidth="35rem" variant="outline" padding="40" display="flex" flexDirection="column" gap="24">
      <div>
        <Text textStyle="heading/h1">Sign in with SSO</Text>
        <Text textStyle="body/md/regular" color="text/secondary">
          Please sign in using your SSO credentials.
        </Text>
      </div>
      <Input
        size="lg"
        type="email"
        label="Email"
        value={email}
        errorText={emailError ? 'Not valid email' : undefined}
        placeholder="Enter your work email"
        isRequired
        onChange={(ev) => {
          setEmailError(Boolean(ev.target.value) && !ev.target.validity.valid);
          setEmail(ev.target.value);
        }}
      />
      <Button isLoading={isPending} size="lg" onClick={() => mutate({ email })} isDisabled={!email || emailError}>
        Continue with SSO
      </Button>
      <Button variant="tertiary" size="md" alignSelf="start" onClick={onBack}>
        Sign in without SSO
      </Button>
    </Card>
  );
};

const SignInPage = () => {
  const [formState, setFormState] = useState<'selection' | 'email' | 'sso'>('selection');
  const [rememberMe, setRememberMe] = useState(false);
  const rememberMePart = typeof rememberMe === 'boolean' ? `?remember_me=${rememberMe}` : '';

  useSignInFlashMessage();

  const { featureFlags } = useContext(GlobalPropsContext);
  const ssoButtonProps = featureFlags.user.emailSamlSignIn
    ? {
        onClick: () => setFormState('sso'),
      }
    : {
        as: 'a' as const,
        href: `/users/sign-in/saml${rememberMePart}`,
      };

  return (
    <PublicPage
      bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, purple.95 100%), neutral.100"
      h="calc(100vh)"
    >
      <StaticHeader>
        <Link colorScheme="purple" href="/users/sign_up">
          Sign up &rarr;
        </Link>
      </StaticHeader>
      <PageTitle textAlign="center">
        <PageTitleHeading size="6">Log in to your account</PageTitleHeading>
        <PageTitleSubheading size="3">Welcome back!</PageTitleSubheading>
      </PageTitle>
      <PageContent paddingTop="0">
        <NotificationSlot />
        {formState !== 'sso' && (
          <LoginCard>
            {formState === 'email' && (
              <Box display="flex" flexDirection="column" gap="24" maxWidth="368">
                <BackButton onBack={() => setFormState('selection')} text="Log in another way" />
                <Text as="h2" fontWeight="bold" size="4">
                  Log in with Email
                </Text>
                <SignInForm rememberMe={rememberMe} />
              </Box>
            )}
            {formState === 'selection' && (
              <LoginButtonGroup
                onEmailSelect={() => setFormState('email')}
                ssoButtonProps={ssoButtonProps}
                signUpMode={false}
                rememberMe={rememberMe}
              />
            )}
          </LoginCard>
        )}
        {formState === 'sso' && <SSOEmailForm onBack={() => setFormState('selection')} />}
        {formState === 'selection' && (
          <Link colorScheme="purple" href="/users/forgot-password" textAlign="center">
            Forgot your password?
          </Link>
        )}
        {formState === 'email' && (
          <Checkbox
            isChecked={rememberMe}
            onChange={(ev) => setRememberMe(ev.currentTarget.checked)}
            alignSelf="center"
          >
            Keep me logged in
          </Checkbox>
        )}
      </PageContent>
    </PublicPage>
  );
};

export default SignInPage;
