import axios, { AxiosRequestHeaders } from 'axios';
import flatten from 'lodash/flatten';
import ENVIRONMENT from '@/environment';
import { camelCaseKeys, snakeCaseKeys, snakeCaseKeysOnlyFirstLevel } from './changeCase';
import { getCsrfTokenCookie, getApiTokenCookie } from './tokens';
import { renewUserAuthTokenPath } from './monolithApiRouteService';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const defaultRequestTransformer = flatten([axios.defaults.transformRequest || []]);

const transformRequest = flatten([snakeCaseKeys, axios.defaults.transformRequest || []]);
export const transformRequestOnlyFirstLevel = flatten([
  snakeCaseKeysOnlyFirstLevel,
  axios.defaults.transformRequest || [],
]);

export const transformResponse = flatten([axios.defaults.transformResponse || [], camelCaseKeys]);

const monolithApi = axios.create({
  headers: {
    ...defaultHeaders,
    'X-CSRF-TOKEN': getCsrfTokenCookie(),
  },
  transformResponse,
  transformRequest,
});

const publicApi = axios.create({
  baseURL: ENVIRONMENT.BITRISE_API_SERVER_URL_FOR_FRONTEND,
  headers: {
    ...defaultHeaders,
  },
  transformResponse,
  transformRequest,
});

let tokenPromise: Promise<any> | null;
const renewApiToken = () => {
  if (!tokenPromise) {
    tokenPromise = monolithApi.post(renewUserAuthTokenPath()).finally(() => {
      tokenPromise = null;
    });
  }
  return tokenPromise;
};

publicApi.interceptors.request.use(async (config) => {
  if (config.publicAccess) {
    return config;
  }
  if (!getApiTokenCookie()) {
    await renewApiToken();
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `token ${getApiTokenCookie()}`,
    } as AxiosRequestHeaders,
  };
});

publicApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;

    // TODO: Waiting for API change to return 401 if token is invalid instead if 500
    // eslint-disable-next-line no-underscore-dangle
    if (error.response /* && error.response.status === 401 */ && !originalConfig._retry) {
      // NOTE To avoid infinite request loop
      // eslint-disable-next-line no-underscore-dangle
      originalConfig._retry = true;

      // The server sets the cookie!
      await renewApiToken();
      return publicApi(originalConfig);
    }

    return Promise.reject(error);
  },
);

export const readPublicApiStream = async (path: string, abortSignal?: AbortSignal) => {
  const url = `${ENVIRONMENT.BITRISE_API_SERVER_URL_FOR_FRONTEND || ''}${path}`;

  if (!getApiTokenCookie()) {
    await renewApiToken();
  }

  return fetch(url, {
    headers: { ...defaultHeaders, Authorization: `token ${getApiTokenCookie()}` },
    signal: abortSignal,
  });
};

export { publicApi, monolithApi };
